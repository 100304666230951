<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">{{$store.back.getText('liste_leads')}} ({{datatable.totalRow}})</h3>
        </div>
        <div class="card-toolbar">
          <b-button v-b-tooltip.hover :title="$store.back.getText('creation_lead')" variant="success" size="sm" class="btn-icon mr-1" href="/#/lead/nouveau"><i class="icon-md fas fa-plus"></i></b-button>
          <a v-b-tooltip.hover :title="$store.back.getText('recherchez')" class="btn btn-icon btn-sm btn-danger mr-1"  v-b-modal.search>
            <i class="icon-md fas fa-search"></i>
          </a>
        </div>
      </div>
      <div class="card-body">
        <b-row>
          <b-col md="1">
            <select class="form-control" v-model="datatable.limitPage" @change="datatableInit">
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </b-col>
        </b-row>
        <b-table responsive striped hover ref="table" :items="datatable.data" :fields="fields" @sort-changed="sortChanged" show-empty empty-text="Il n'y a aucun enregistrement à afficher">
          <template #cell(id)="data">
            <a :href="'/#/lead/detail/'+data.value">{{ data.value }}</a>
          </template>
          <template #cell(nom)="data">
            <a :href="'/#/lead/detail/'+data.item.id">{{ data.value }}</a>
          </template>
          <template #cell(status_id)="data">
            <b-button class="btn-status" :variant="params.LeadStatus.find(s => s.id === data.value).color">{{params.LeadStatus.find(s => s.id === data.value).titre}}</b-button>
          </template>
          <template #cell(regime_id)="data">
            {{params.Regime.find(s => s.id === data.value).titre}}
          </template>
          <template #cell(user_id)="data">
            {{params.MyUsers.users.find(c => c.id === data.value).prenom}} {{params.MyUsers.users.find(c => c.id === data.value).nom[0]}}.
          </template>
          <template #cell(date_create)="data">
            {{$store.back.timestampToDate(data.value)}}
          </template>
        </b-table>
        <b-pagination size="lg" align="center" v-model="datatable.currentPage" @change="changePage" :total-rows="datatable.totalRow" :per-page="datatable.limitPage" aria-controls="itemList"></b-pagination>
      </div>
    </div>
    <b-modal id="search" title="Recherche" size="xl" ref="myModalDeleteRef">
      <div class="row">
        <div class="form-group col-md-4">
          <label>Nom</label>
          <input type="text" placeholder="Rechercher par nom" v-model="filters.nom" class="form-control">
        </div>
        <div class="form-group col-md-4">
          <label>Regime</label>
          <select class="form-control" v-model="filters.regime_id">
            <option :value="null">Tout</option>
            <option v-for="s in params.Regime" :value="s.id" :key="s.id">{{s.titre}}</option>
          </select>
        </div>
        <div class="form-group col-md-4">
          <label>{{$store.back.getText('status')}}</label>
          <select class="form-control" v-model="filters.status_id">
            <option :value="null">Tout</option>
            <option v-for="s in params.LeadStatus" :value="s.id" :key="s.id">{{s.titre}}</option>
          </select>
        </div>
        <div class="form-group col-md-4">
          <label>{{$store.back.getText('user')}}</label>
          <select class="form-control" v-model="filters.user_id">
            <option :value="null">Tout</option>
            <option v-for="u in params.MyUsers.users" :value="u.id" :key="u.id">{{u.prenom}} {{u.nom[0]}}.</option>
          </select>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right" @click="datatableInit">
            Recherche
          </b-button>
          <b-button variant="danger" class="float-right mr-2" @click="$refs.myModalDeleteRef.hide();">
            Fermer
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data(){
    return {
      items: [],
      fields: [
        {
          key: 'id',
          label: this.$store.back.getText('ref'),
          sortable:true
        },
        {
          key: 'nom',
          label: this.$store.back.getText('nom'),
          sortable:true
        },
        {
          key: 'regime_id',
          label: "Regime",
          sortable:true
        },
        {
          key: 'user_id',
          label: this.$store.back.getText('user'),
          sortable:true
        },
        {
          key: 'date_create',
          label: this.$store.back.getText('date_create'),
          sortable:true
        },
        {
          key: 'status_id',
          label: this.$store.back.getText('status'),
          sortable:true
        }
      ],
      datatable:{
        currentPage: 1,
        limitPage: 20,
        totalRow: 0,
        data: []
      },
      sort:{
        field: 'id',
        value: 'DESC'
      },
      params:{
        status:[],
        LeadStatus:[],
        MyUsers:{
          ids:[],
          users:[]
        }
      },
      filters:{
        "nom":null,
        "prenom": null,
        "user_id":null,
        "regime_id":null,
        "status_id": null
      },
    };
  },
  components: {
  },
  watch: {
  },
  beforeMount() {
    this.$store.back.ajax('/magic/all', null, (res) => {
      if(res.status === true){
        this.params = res.data;
      }
    });
  },
  methods: {
    datatableInit: function(){
      var params = {
        filters: this.filters,
        sort: this.sort,
        limit: this.datatable.limitPage,
        page: this.datatable.currentPage
      }
      this.$store.back.ajax('/lead/liste', params, (res) => {
        this.datatable = res;
      });
    },
    sortChanged(e){
      this.sort = {
        field: e.sortBy,
        value: e.sortDesc === true ? "DESC" : "ASC"
      };
      this.datatable.data = [];
      this.datatableInit();
    },
    changePage(e){
      this.datatable.currentPage = e;
      this.datatableInit();
    },
  },
  computed: {
  },
  mounted() {
    this.datatableInit();
  }
};
</script>
<style media="screen">
  .btn-status{
    font-size: 1.025rem;
    line-height: 1.35;
    border-radius: 0.42rem;
    padding: 5px 3px;
  }
</style>
